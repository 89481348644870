import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './services1.css'; 
import SampleImage from '../assests/hi.gif'; 
import Buy from '../assests/propertysearch.gif'; 
import Rent from '../assests/rent.gif'; 
import Invest from '../assests/invest management.gif'; 
import Footer from '../home/footer';
import TermsAndConditions from './termsandcondition';

const ImageDetails = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="main-container">
      <div className="image-detail" data-aos="fade-up">
        <img src={Buy} alt=" 1" className="image" />
        <div className="details">
          <h2>Buy/Sell</h2>
          <p>
          <p> <strong>Tailored Property Search</strong><br />
     We know that your home is more than just a place; it's where memories are made. That’s why we take the time to understand your lifestyle, preferences, and dreams. We don’t show you endless options—we carefully select homes that reflect your personal taste, ensuring that every visit feels like a step closer to the perfect fit.<br /><br />

      <strong>Vastu Alignment</strong><br />
       A home is not just about its look, but also the energy it holds. If Vastu is important to you, we ensure that every property we present aligns with principles that enhance harmony and positive energy, helping you create a space that feels balanced and serene from day one.<br /><br />

       <strong>Curated Property Visits</strong><br />
       We know your time is precious, so we arrange visits only to the properties that truly resonate with your preferences. Whether it’s the view, the layout, or the energy of the space, we make sure each visit brings you closer to your dream home.<br /><br />

       <strong>Seamless Legal Paperwork</strong><br />
       Buying or selling a home involves a lot of legalities, but we’re here to take that stress off your shoulders. From contracts to registrations, we handle all the paperwork with meticulous attention to detail, ensuring a smooth and worry-free process.<br /><br />

       <strong>Loan Assistance</strong><br />
       If financing your home is part of the journey, we offer full support in securing a loan that works best for you. From finding the right loan to guiding you through the process, we ensure you feel confident and comfortable every step of the way.<br /><br />

       <strong>Loan Foreclosure Support</strong><br />
       Should you need assistance with loan foreclosure, we provide expert guidance to make the process as smooth as possible, ensuring that your financial path remains clear and uncomplicated.<br /><br />

      <strong>No Objection Certificate (NOC) Support</strong><br />
      Navigating the legalities of property transactions can be daunting, but we’re here to make it easy. We assist in obtaining the No Objection Certificate from the builder, ensuring that all necessary clearances are in place without you having to worry about a thing.<br /><br />

       <strong>Deep Cleaning & Repairs</strong><br />
       Moving into a new home should feel exciting, not stressful. We take care of any required deep cleaning or repairs, making sure your new space is spotless and ready for you to make it your own from the moment you step in.<br /><br />

       <strong>Investment Guidance</strong><br />
       If you’re purchasing a property for investment, we’re here to help you maximize its potential. Whether you're looking for a great rental deal or seeking to sell at the best possible price, we offer expert advice to ensure your investment yields the returns you deserve.<br /><br /></p>
          </p>
        </div>
      </div>
      <div className="image-detail reverse" data-aos="fade-up" data-aos-delay="200">
        <img src={Rent} alt=" 2" className="image" />
        <div className="details">
          <h2>Rent</h2>
          <p> <strong>Personalized Property Search</strong><br />
      We understand that a home is not just a place to stay but where comfort and convenience meet. For tenants, we carefully select properties that suit your lifestyle and preferences, making sure you find the perfect home, while for owners, we ensure your property is rented to responsible tenants who appreciate the space.<br /><br />

      <strong>Vastu Alignment</strong><br />
      Whether you’re a tenant looking for a space that brings peace and balance or an owner wanting to offer a harmonious living environment, we ensure that each property meets essential Vastu guidelines, promoting a positive and balanced flow of energy.<br /><br />

      <strong>Legal Paperwork Assistance</strong><br />
      Navigating the legal side of renting can be overwhelming, but we make it simple. For both tenants and owners, we handle all the legal documentation with care and precision, ensuring a smooth rental agreement that protects the interests of both parties.<br /><br />

      <strong>Society Paperwork Before Move-In</strong><br />
      Moving into a new home often requires clearances from the housing society. We take care of all society-related paperwork before your move-in date, ensuring that you, as a tenant, experience a hassle-free transition and as an owner, you can rest assured that everything is in order.<br /><br />

      <strong>Repairs & Cleaning</strong><br />
      We make sure the property is move-in ready by taking care of any necessary repairs and deep cleaning. Tenants can expect a spotless, well-maintained home, while owners can trust that their property is kept in top condition.<br /><br />

      <strong>Dedicated Relationship Manager</strong><br />
      To ensure a seamless experience for both tenants and owners, we provide a dedicated relationship manager. For tenants, this means having someone available to handle any concerns or requests, allowing you to feel relaxed and supported. For owners, we ensure that you can enjoy peace of mind, knowing your property is being managed without disturbance.<br /><br />

      <strong>On-Call Support Team</strong><br />
      From maids to painters, carpenters to electricians, we have a dedicated team of professionals available to provide any maintenance or service required during the tenant’s stay. This ensures a hassle-free living experience for tenants and keeps the property in excellent condition for owners.<br /><br />

      <strong>Porter Services for Move-In</strong><br />
      Moving can be stressful, but we take that burden off your shoulders. Our porter service helps tenants move into their new home with ease, ensuring that the process is smooth and organized, exactly as per your needs and preferences.<br /><br />

      <strong>Move-In and Move-Out Reports</strong><br />
      We conduct thorough inspections during both move-in and move-out to assess the condition of the property. This ensures transparency and protects both the tenant’s security deposit and the owner’s property, keeping everyone informed about any changes or damages.<br /><br /></p>
        </div>
      </div>
      <div className="image-detail" data-aos="fade-up" data-aos-delay="400">
        <img src={SampleImage} alt=" 3" className="image" />
        <div className="details">
          <h2>Architecture</h2>
                     <p><strong>ARCHITECTURE </strong><br></br>
                  Architecture is the disciplined fusion of art and science, dedicated to designing spaces that enrich human experience and functionality. It integrates creativity with technical expertise to achieve a harmonious balance of aesthetics, utility, and sustainability. By addressing both the practical and aspirational needs of society, architecture transcends construction, shaping environments that inspire, endure, and foster meaningful connections between people and their surroundings.<br></br>
                  <strong>INTERIOR DESIGN</strong><br></br>
           
                  For us Interior design is the artful crafting of indoor environments that balance beauty, comfort, and functionality. It involves the thoughtful selection of materials, colors, furnishings, and spatial layouts to create spaces that reflect personality while enhancing the daily experience. The interior design integrates form with purpose, ensuring that every element contributes to a harmonious and inviting atmosphere, elevating how we live, work, and interact within a space. <br></br>
                  <strong>HEALTHCARE ARCHITECTURE</strong><br></br>

                In our approach to healthcare architecture, We prioritize creating environments that go beyond mere functionality to actively support healing and well-being. We believe that thoughtfully designed spaces should foster comfort and serenity while maintaining the highest standards of clinical efficiency. By integrating flexibility, safety, and forward-thinking design solutions, Our focus is on crafting healthcare facilities that not only meet current needs but are adaptable to future innovations. Ultimately, Our goal is to create spaces that enhance the human experience, benefiting both patients and healthcare providers.<br></br>
                <strong>LANDSCAPE ARCHITECTURE </strong><br></br>
                
                Landscape architecture blends the art of design with environmental science to create outdoor spaces that are both functional and aesthetically pleasing. It involves the thoughtful integration of natural elements—such as terrain, vegetation, and water features—with built structures to develop sustainable, visually compelling environments. Focused on enhancing the connection between people and nature, landscape architecture aims to foster ecological balance, promote well-being, and create spaces that inspire and endure.<br></br>
                <strong>DESIGN CONSULTANCY</strong><br></br>
                
                Design consultancy provides expert guidance and creative solutions across all phases of a project, from concept to completion. It involves a collaborative approach to addressing design challenges, offering tailored strategies that align with the client's vision, goals, and practical needs. By leveraging technical expertise and innovative thinking, We ensure that projects are not only aesthetically compelling but also functionally efficient and sustainable, delivering outcomes that enhance both form and function.<br></br>
                </p>

        </div>
      </div>
      <div className="image-detail reverse" data-aos="fade-up" data-aos-delay="600">
        <img src={Invest} alt=" 4" className="image" />
        <div className="details">
          <h2>Investment Management</h2>
          <p> <strong>Property Acquisition and Investment Strategy</strong> <br></br>
               Market Research Analyzing local real estate trends to identify high-potential properties.<br></br>
               Investment Planning Advising on short- and long-term investment goals, potential ROI, and risk assessment.<br></br>
                   <strong>Property Selection:</strong> <br></br>Helping investors acquire residential property on their financial goals.<br></br>
               Due Diligence Conducting a thorough analysis, including property condition assessments, legal checks, and financial feasibility.<br></br>
     
                <strong>Financial Management</strong><br></br>
               Financial Reporting: Providing regular updates on the property’s financial performance, including rental income, operating expenses, and ROI.<br></br>
               
               <strong>Legal and Compliance Management</strong><br></br>
               Regulatory Compliance: Ensuring that the property adheres to local regulations, including zoning laws, safety standards, and rental regulations.
               Legal Paperwork: Handling lease agreements, property registration, and eviction processes (if necessary).
               Insurance: Assisting with appropriate property insurance to mitigate risk.<br></br>
               
               <strong> Property Sale and Exit Strategies</strong><br></br>
               Market Timing: Advising on the right time to sell a property to maximize returns.<br></br>
               
                <strong>Portfolio Diversification</strong><br></br>
               Multi-Property Investment: Advising investors on building a diverse real estate portfolio.
               Geographic Expansion: Helping investors explore markets in different cities or countries for further diversification.</p>
        </div>
      </div>
      {/* <div className="image-detail" data-aos="fade-up" data-aos-delay="400">
        <img src={SampleImage} alt='loan' className="image" />
        <div className="details">
          <h2>loan</h2>
                     <p><strong>loan</strong><br></br>
                  Architecture is the disciplined fusion of art and science, dedicated to designing spaces that enrich human experience and functionality. It integrates creativity with technical expertise to achieve a harmonious balance of aesthetics, utility, and sustainability. By addressing both the practical and aspirational needs of society, architecture transcends construction, shaping environments that inspire, endure, and foster meaningful connections between people and their surroundings.<br></br>
                  <strong>INTERIOR DESIGN</strong><br></br>
           
                  For us Interior design is the artful crafting of indoor environments that balance beauty, comfort, and functionality. It involves the thoughtful selection of materials, colors, furnishings, and spatial layouts to create spaces that reflect personality while enhancing the daily experience. The interior design integrates form with purpose, ensuring that every element contributes to a harmonious and inviting atmosphere, elevating how we live, work, and interact within a space. <br></br>
                  <strong>HEALTHCARE ARCHITECTURE</strong><br></br>

                In our approach to healthcare architecture, We prioritize creating environments that go beyond mere functionality to actively support healing and well-being. We believe that thoughtfully designed spaces should foster comfort and serenity while maintaining the highest standards of clinical efficiency. By integrating flexibility, safety, and forward-thinking design solutions, Our focus is on crafting healthcare facilities that not only meet current needs but are adaptable to future innovations. Ultimately, Our goal is to create spaces that enhance the human experience, benefiting both patients and healthcare providers.<br></br>
                <strong>LANDSCAPE ARCHITECTURE </strong><br></br>
                
                Landscape architecture blends the art of design with environmental science to create outdoor spaces that are both functional and aesthetically pleasing. It involves the thoughtful integration of natural elements—such as terrain, vegetation, and water features—with built structures to develop sustainable, visually compelling environments. Focused on enhancing the connection between people and nature, landscape architecture aims to foster ecological balance, promote well-being, and create spaces that inspire and endure.<br></br>
                <strong>DESIGN CONSULTANCY</strong><br></br>
                
                Design consultancy provides expert guidance and creative solutions across all phases of a project, from concept to completion. It involves a collaborative approach to addressing design challenges, offering tailored strategies that align with the client's vision, goals, and practical needs. By leveraging technical expertise and innovative thinking, We ensure that projects are not only aesthetically compelling but also functionally efficient and sustainable, delivering outcomes that enhance both form and function.<br></br>
                </p>

        </div> */}
      {/* </div> */}
      <TermsAndConditions/>
      <Footer/>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30px' }}>
      <p style={{ textAlign: 'center', fontSize: '16px', margin: '0' }}>&copy; 2024 Akhtar Realtors. All Rights Reserved.</p>
    </div>
    </div>
  );
};

export default ImageDetails;
