import React from 'react';
import './Topnavbar.css';
import { FaWhatsapp, FaLinkedinIn, FaInstagram, FaEnvelope } from 'react-icons/fa'; 
import logo from '../assests/akhtarrealtorlogo.png';

const TopNavbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div className="navbar-social-media">
       
        <a href="mailto:info@akhtarrealtors.com" className="social-icon" target="_blank" rel="noopener noreferrer">
          <FaEnvelope size={20} />
        </a>
       
        <a href="https://wa.me/9193106653" className="social-icon" target="_blank" rel="noopener noreferrer">
          <FaWhatsapp size={20} />
        </a>
       
        <a href="https://www.linkedin.com/company/akhtar-realtors-indian-real-estate-matchmakers/" className="social-icon" target="_blank" rel="noopener noreferrer">
          <FaLinkedinIn size={20} />
        </a>
       
        <a href="https://www.instagram.com/akhtar_realtors?igsh=aDFpdjJycWtuOTBt" className="social-icon" target="_blank" rel="noopener noreferrer">
          <FaInstagram size={20} />
        </a>
      </div>
    </nav>
  );
};

export default TopNavbar;

