import React from "react";
import { Carousel } from "antd";
import styled from "styled-components";
import Snehal from '../assests/snehal.jpeg';
import Dhaval from '../assests/dhaval.jpeg';
import Asim from '../assests/asim.jpeg';
import Umesh from '../assests/umesh.jpeg';
import Gaurav from '../assests/gaurav.jpeg';
import Doctors from '../assests/dr&dr.jpg';

// Styled Components with media queries
const Section = styled.section`
  width: 97%;
  padding: 20px;
  background-color: #f8f9fa;
  text-align: center;
  font-family: "Satisfy", cursive;
  font-weight: 400;
  font-style: normal;
  border-radius: 10px;

  @media (max-width: 768px) {
    padding: 10px;
    width: 100%;
  }
`;

const Heading = styled.h2`
  width: 100%;
  margin: 0;
  padding: 20px 0;
  font-size: 2.5em;
  background-color: #343a40;
  color: white;
  border-radius: 10px;

  @media (max-width: 768px) {
    font-size: 1.8em;
    padding: 15px 0;
  }
`;

const TestimonialContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
  padding: 20px;
  height: auto;
  margin: 0 auto;
  font-weight: 400;
  font-size: 2rem;
  font-style: normal;

  // Default layout for larger screens
  @media (min-width: 769px) {
    flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
  }

  // Stack image above text for smaller screens
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
    font-size: 1.5rem;
  }
`;

const Image = styled.img`
  width: 350px;
  height: 350px;
  border-radius: 50%;
  object-fit: cover;
  transition: all 0.5s ease-in-out;

  @media (max-width: 768px) {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
  }
`;

const Details = styled.div`
  flex: 1;
  padding: 20px;
  font-size: 2rem;
  text-align: ${(props) => (props.right ? "right" : "left")};
  
  @media (max-width: 768px) {
    padding: 10px;
    font-size: 1.2rem;
    text-align: center;
  }
`;

const CardTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #1a1a1a;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const CardText = styled.p`
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #555;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
`;

// Testimonial component
const Testimonial = ({ testimony, index }) => (
  <TestimonialContainer reverse={index % 2 !== 0}>
    <Image src={testimony.photo} alt={testimony.name} />
    <Details right={index % 2 !== 0}>
      <CardText>{testimony.text}</CardText>
      <CardTitle>{testimony.name}</CardTitle>
    </Details>
  </TestimonialContainer>
);

// Main Component for Testimonials
const Testimony = () => {
  const testimonials = [
    {
      name: "- Snehal Wagh",
      text: "I highly recommend Akhtar Realtors to anyone looking for a rental or buying a home in a good locality. After dealing with unreliable brokers I was impressed by how well Akhtar Realtors understood my needs and budget. They not only found me the perfect home but also offered add-on services like dedicated electricians, carpenters, and plumbers, making the move-in process easy. From a legal perspective, they ensured transparent communication between us and the property owner so neither of us had to worry about minor issues. Their team takes care of every problem we face and it's always sorted within a day. It’s been a year since I moved in and everything has been smooth and hassle-free. I'll definitely trust them for future rentals or home purchases! PFA, the view from my house.",
      photo: Snehal,
    },
    {
      name: "- Dhaval Mistry",
      text: "We had the pleasure of working with Uzma Akhtar and Aliya Akhtar from Akhtar Realtors | Indian Real Estate Matchmakers to find a new rental house. Despite the many ups and downs throughout the transaction, they consistently went above and beyond to support us. Uzma and Aliya were always available to answer our queries and address any doubts we had, making the entire process much smoother. Their dedication and commitment to helping us find the perfect home were truly commendable. I highly recommend Akhtar Realtors for anyone in need of real estate services. Thank you, Uzma and Aliya, for your exceptional support!",
      photo: Dhaval,
    },
    {
      name: "- Dr. Pragalbha and Dr. Amol Bhanushali.",
      text: "They helped me rent out my flat. Very prompt, helpful and professional. Thanks Team Uzma!",
      photo: Doctors,
    },
    {
      name: "- Asim Khan",
      text: "One of the best decisions I made was trusting the Akhtar sisters to help me and my wife find our forever home. Not your typical realtors. It feels like they are actually emotionally involved in making sure you find a home. From searching to moving in to making sure your stay is comfortable…every step they are there.",
      photo: Asim,
    },
    {
      name: "- Manika & Gaurav",
      text: "Akhtar realtors provides excellent Service , prompt resolution to queries & understands basic customer requirements very well. They understood our requirements & showed us the properties which resonates with us greatly. Kudos to them!!",
      photo: Gaurav,
    },
    {
      name: "- Mr. Umesh Chandratre",
      text: "Hi I recently bought a flat in Vatsal Piramal Vaikunth . I am very happy with the service I received from Akhtar Realtors. I am so glad that I chose Akhtar realtors for my purchase . I highly recommend them to anyone looking for top notch real estate services.",
      photo: Umesh,
    },
  ];

  return (
    <Section>
      <Heading>Client Testimonials</Heading>
      <Carousel autoplay autoplaySpeed={3000}>
        {testimonials.map((testimony, index) => (
          <Testimonial key={index} testimony={testimony} index={index} />
        ))}
      </Carousel>
    </Section>
  );
};

export default Testimony;
