import React from 'react';
import styled from 'styled-components';
// import photo2 from '../assests/ourteam2.jpeg'
import Mehwish from '../assests/mehwish.gif'
import Shruti from '../assests/shruti123.gif'
import Shilpi from '../assests/shilpi.gif'
import sakshi from '../assests/sakshi.gif'

// Styled Components
const CardsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
`;

const MainHeading = styled.div`
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 99%;
  text-align: center;
  font-size: 2rem;
  margin: 10px;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin: 5px;
  }
`;

const CardContainer = styled.div`
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 450px;
  height: 600px;
  margin: 10px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const CardImage = styled.img`
  width: 60%;
  height: 30%;

  @media (max-width: 768px) {
    width: 80%;
    height: auto;
  }
`;

const CardContent = styled.div`
  padding: 10px;
  gap: 0.5;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

const CardTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
  font-family: "EB Garamond", serif;
  font-weight: 900;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
`;

const CardDescription = styled.p`
  font-size: 1.5rem;
  color: #666;
  line-height: 1;
  font-family: "EB Garamond", serif;
  font-weight: 500;
  width: 100%;
  height: 70%;
  padding-bottom: 8px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 1rem;
    height: auto;
  }
`;

const CardDescriptions = styled.p`
  font-size: 1.2rem;
  color: #666;
  line-height: 1;
  font-family: "EB Garamond", serif;
  font-weight: 800;
  width: 100%;
  padding-bottom: 8px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

function Cards() {
  return (
   <MainHeading>
     <h2>Our Team</h2>
     <CardsWrapper>
       <CardContainer>
         <CardImage src={Shilpi} alt="Sakshi" />
         <CardContent>
           <CardTitle>Sakshi Vishwakarma</CardTitle>
           <CardDescriptions>
             Business Development Executive <br />
             <h4><span style={{ fontStyle: 'italic' }}>Email - onboardingpartner@akhtarrealtors.in</span></h4>
           </CardDescriptions>
           <CardDescription>
             Sakshi Vishwakarma, the Business Development Executive, is the initial contact for flat owners. She ensures clear communication, clarifies rental terms, and aligns sale or rent expectations. Her polite and attentive approach helps her connect with owners and provide tailored solutions to meet their needs.
           </CardDescription>
         </CardContent>
       </CardContainer>

       <CardContainer>
         <CardImage src={sakshi} alt="Shilpi" />
         <CardContent>
           <CardTitle>Shilpi Roy</CardTitle>
           <CardDescriptions>
             Business Development Manager <br />
             <h4><span style={{ fontStyle: 'italic' }}>Email - shilpi@akhtarrealtors.in</span></h4>
           </CardDescriptions>
           <CardDescription>
             Shilpi Roy, the Business Development Manager, serves as the first point of contact for tenants and buyers. With her deep market knowledge and excellent listening skills, she understands their specific requirements and ensures they are shown properties that perfectly align with their needs.
           </CardDescription>
         </CardContent>
       </CardContainer>

       <CardContainer>
         <CardImage src={Mehwish} alt="Mehwish" />
         <CardContent>
           <CardTitle>Mehwish Khan</CardTitle>
           <CardDescriptions>
             Operations Manager <br />
             <h4><span style={{ fontStyle: 'italic' }}>Email - mehwish@akhtarrealtors.in</span></h4>
           </CardDescriptions>
           <CardDescription>
             Mehwish Khan, the Operations Manager, handles rental paperwork, including property registration and police verification. She ensures apartments are cleaned before tenants arrive and manages the move-in process for a smooth transition. Her proactive and efficient approach ensures tasks are completed ahead of schedule.
           </CardDescription>
         </CardContent>
       </CardContainer>

       <CardContainer>
         <CardImage src={Shruti} alt="Shruti" />
         <CardContent>
           <CardTitle>Shruti</CardTitle>
           <CardDescriptions>
             Operations Manager <br />
             <h4><span style={{ fontStyle: 'italic' }}>Email - shruti@akhtarrealtors.in</span></h4>
           </CardDescriptions>
           <CardDescription>
             Shruti, an Operations Manager, handles all legal paperwork for outright transactions. She oversees property registration and manages every aspect of the ownership transfer from the previous owner to the new buyer. With her smart and efficient approach, Shruti ensures a smooth and timely handover of keys to the buyer.
           </CardDescription>
         </CardContent>
       </CardContainer>
     </CardsWrapper>
   </MainHeading>
  );
}

export default Cards;
