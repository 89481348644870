import {React,useState} from 'react';
import AkhtarHome from './component/home/akhtarhome'; 
import Navbar from './component/mainnavbar/navbar'
import TopDisplay from './component/topdisplayfile/topdisplay';
import TopNavbar from './component/topnavbar/Topnavbar';
import AboutUS from './component/aboutus/aboutus';
// import Services from './component/services/services';
import Services from './component/services/services1';
import Architecture from './component/Architecture/Architecture'
import Contact from './component/contact/contact'
const App = () => {
  const appStyle = {
    backgroundColor: '#EEE6D8', // Replace with your desired color
    // minHeight: '100vh',
    // margin: 0,
    // padding: 0,
};
    const  [value,setValue]= useState("home")
  return (
    <div style={appStyle}>

        <TopNavbar/>
        <TopDisplay/>
        <Navbar handleNavChange={(key)=>setValue(key)}/>
        {value==='home'&&<AkhtarHome />}
        {value==='aboutus'&&<AboutUS/>}
        {value==='architech'&&<Architecture />}
        {value==='service'&&<Services/>}
        {value==='contact'&&< Contact/>}
    </div>
  );
};

export default App;
