import React, { useState } from 'react';
import Modal from 'react-modal';
import { useSwipeable } from 'react-swipeable';
import './projects.css'; 

// Importing images
import HospitalThumbnail from '../assests/projects/Banaras hospital/113.jpg';
import Hospital1 from '../assests/projects/Banaras hospital/5_Photo - 5.jpg';
import Hospital2 from '../assests/projects/Banaras hospital/5_Photo - 2.jpg';
import Hospital3 from '../assests/projects/Banaras hospital/4a.jpg';
import Hospital4 from '../assests/projects/Banaras hospital/11a.jpg';
import Hospital5 from '../assests/projects/Banaras hospital/6A.jpg';
import Hospital6 from '../assests/projects/Banaras hospital/jj.jpg';

import AashuHouseThumbnail from '../assests/projects/AASHU/photo-output 8.jpeg';
import AashuHouse1 from '../assests/projects/AASHU/photo-output 8.jpeg';
import AashuHouse2 from '../assests/projects/AASHU/photo-output 9.jpeg';
import AashuHouse3 from '../assests/projects/AASHU/photo-output 10.jpeg';
import AashuHouse4 from '../assests/projects/AASHU/photo-output 11.jpeg';

import AhmedAliHouseThumbnail from '../assests/projects/AHMED ALI FUKKAT/10.jpg';
import AhmedHouse1 from '../assests/projects/AHMED ALI FUKKAT/10.jpg';
import AhmedHouse2 from '../assests/projects/AHMED ALI FUKKAT/11.jpg';

import ApartmentThumbnail from '../assests/projects/APARTMENT/cvb.jpg';
import Apartment1 from '../assests/projects/APARTMENT/cvb.jpg';
import Apartment2 from '../assests/projects/APARTMENT/dcfv.jpg';
import Apartment3 from '../assests/projects/APARTMENT/fgh.jpg';
import Apartment4 from '../assests/projects/APARTMENT/frfr.jpg';
import Apartment5 from '../assests/projects/APARTMENT/gtjngj.jpg';
import Apartment6 from '../assests/projects/APARTMENT/vbhjn.jpg';
import Apartment7 from '../assests/projects/APARTMENT/vgbnjm.jpg';


import BilalMosqueThumbnail from '../assests/projects/bilal mosque/10.jpg';
import BilalMosque1 from '../assests/projects/bilal mosque/3.jpg';
import BilalMosque2 from '../assests/projects/bilal mosque/6.jpg';
import BilalMosque3 from '../assests/projects/bilal mosque/7.jpg';
import BilalMosque4 from '../assests/projects/bilal mosque/10.jpg';

import ChillyPepperRooftopThumbnail from '../assests/projects/chilly pepper rooftop/3a.jpg';
import ChillyPepperRooftop1 from '../assests/projects/chilly pepper rooftop/55.jpg';
import ChillyPepperRooftop2 from '../assests/projects/chilly pepper rooftop/8.jpg';
import ChillyPepperRooftop3 from '../assests/projects/chilly pepper rooftop/3a.jpg';
import ChillyPepperRooftop4 from '../assests/projects/chilly pepper rooftop/e33e.jpg';
import ChillyPepperRooftop5 from '../assests/projects/chilly pepper rooftop/ede.jpg';
import ChillyPepperRooftop6 from '../assests/projects/chilly pepper rooftop/u8.jpg';
import ChillyPepperRooftop7 from '../assests/projects/chilly pepper rooftop/uu.jpg';

import CubeHouseThumbnail from '../assests/projects/cube house/11c.jpg';
import CubeHouse1 from '../assests/projects/cube house/12a.jpg';
import CubeHouse2 from '../assests/projects/cube house/6.jpg';
import CubeHouse3 from '../assests/projects/cube house/8.jpg';
import CubeHouse4 from '../assests/projects/cube house/8b.jpg';
import CubeHouse5 from '../assests/projects/cube house/9b.jpg';
import CubeHouse6 from '../assests/projects/cube house/9e.jpg';
import CubeHouse7 from '../assests/projects/cube house/11c.jpg';


import DesignUniversityThumbnail from '../assests/projects/DESIGN UNIVERSITY/cdcd.jpg';
import DesignUniversity1 from '../assests/projects/DESIGN UNIVERSITY/e3e33.jpg';
import DesignUniversity2 from '../assests/projects/DESIGN UNIVERSITY/ff.jpg';
import DesignUniversity3 from '../assests/projects/DESIGN UNIVERSITY/fff.jpg';
import DesignUniversity4 from '../assests/projects/DESIGN UNIVERSITY/from canteen elevation.jpg';
import DesignUniversity5 from '../assests/projects/DESIGN UNIVERSITY/university students.jpg';
import DesignUniversity6 from '../assests/projects/DESIGN UNIVERSITY/cdcd.jpg';

import FashionInstituteThumbnail from '../assests/projects/fashion Institute/dfdf.jpg';
import FashionInstitute1 from '../assests/projects/fashion Institute/edeee33.jpg';
import FashionInstitute2 from '../assests/projects/fashion Institute/fbfb.jpg';
import FashionInstitute3 from '../assests/projects/fashion Institute/hyyy.jpg';
import FashionInstitute4 from '../assests/projects/fashion Institute/swww.jpg';
import FashionInstitute5 from '../assests/projects/fashion Institute/uiiu.jpg';
import FashionInstitute6 from '../assests/projects/fashion Institute/dfdf.jpg';

import HospitalJaipurThumbnail from '../assests/projects/HOSPITAL JAIPUR/1.jpg';
import HospitalJaipur1 from '../assests/projects/HOSPITAL JAIPUR/2a.jpg';
import HospitalJaipur2 from '../assests/projects/HOSPITAL JAIPUR/3a.jpg';
import HospitalJaipur3 from '../assests/projects/HOSPITAL JAIPUR/4a.jpg';
import HospitalJaipur4 from '../assests/projects/HOSPITAL JAIPUR/de.jpg';
import HospitalJaipur5 from '../assests/projects/HOSPITAL JAIPUR/1.jpg';






// Sample projects data
const projectData = [
  {
    id: 1,
    title: 'Banaras Hospital',
    description: 'This hospital is a modern health facility located in Banaras, known for its advanced technology and patient care services.',
    thumbnail: HospitalThumbnail,
    images: [
      { src: Hospital1 },
      { src: Hospital2 },
      { src: Hospital3 },
      { src: Hospital4 },
      { src: Hospital5 },
      { src: Hospital6 },
    ],
  },
  {
    id: 2,
    title: 'Walk In Wardrobe',
    description: '',
    thumbnail: AashuHouseThumbnail,
    images: [
      { src: AashuHouse1 },
      { src: AashuHouse2 },
      { src: AashuHouse3 },
      { src: AashuHouse4 },
    ],
  },
  {
    id: 3,
    title: "Tiny House",
    description: '',
    thumbnail: AhmedAliHouseThumbnail,
    images: [
      { src: AhmedHouse1 },
      { src: AhmedHouse2 },
    ],
  },
  {
    id: 4,
    title: 'Apartment',
    description: '',
    thumbnail: ApartmentThumbnail,
    images: [
      { src: Apartment1 },
      { src: Apartment2 },
      { src: Apartment3 },
      { src: Apartment4 },
      { src: Apartment5 },
      { src: Apartment6 },
      { src: Apartment7 },
    ],
  },
  {
    id: 5,
    title: 'Bilal Mosque',
    description: '',
    thumbnail: BilalMosqueThumbnail,
    images: [
      { src: BilalMosque1 },
      { src: BilalMosque2 },
      { src: BilalMosque3 },
      { src: BilalMosque4 },
      
    ],
  },
  {
    id: 6,
    title: 'Chilly Pepper Rooftop Restro',
    description: '',
    thumbnail: ChillyPepperRooftopThumbnail,
    images: [
      { src: ChillyPepperRooftop1 },
      { src: ChillyPepperRooftop2 },
      { src: ChillyPepperRooftop3 },
      { src: ChillyPepperRooftop4 },
      { src: ChillyPepperRooftop5 },
      { src: ChillyPepperRooftop6 },
      { src: ChillyPepperRooftop7 },
    ],
  },
  {
    id: 7,
    title: 'Cube House',
    description: '',
    thumbnail: CubeHouseThumbnail,
    images: [
      { src: CubeHouse1 },
      { src: CubeHouse2 },
      { src: CubeHouse3 },
      { src: CubeHouse4 },
      { src: CubeHouse5 },
      { src: CubeHouse6 },
      { src: CubeHouse7 },
    ],
  },
  {
    id: 8,
    title: 'Design University',
    description: '',
    thumbnail: DesignUniversityThumbnail,
    images: [
      { src: DesignUniversity1 },
      { src: DesignUniversity2 },
      { src: DesignUniversity3 },
      { src: DesignUniversity4 },
      { src: DesignUniversity5 },
      { src: DesignUniversity6 },
      
    ],
  },
  {
    id: 9,
    title: 'Fashion Institute',
    description: '',
    thumbnail: FashionInstituteThumbnail,
    images: [
      { src: FashionInstitute1 },
      { src: FashionInstitute2 },
      { src: FashionInstitute3 },
      { src: FashionInstitute4 },
      { src: FashionInstitute5 },
      { src: FashionInstitute6 },
      
    ],
  },
  {
    id: 10,
    title: 'Hospital Jaipur',
    description: '',
    thumbnail: HospitalJaipurThumbnail,
    images: [
      { src: HospitalJaipur1 },
      { src: HospitalJaipur2 },
      { src: HospitalJaipur3 },
      { src: HospitalJaipur4 },
      { src: HospitalJaipur5 },
      ],
  },
  
];


Modal.setAppElement('#root');

const Projects = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleProjectClick = (project) => {
    setSelectedProject(project);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedProject(null);
    setSelectedImage(null);
  };

  const handleBackClick = () => {
    setSelectedImage(null);
    setSelectedProject(null);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (selectedImage) {
        const currentIndex = selectedProject.images.indexOf(selectedImage);
        if (currentIndex < selectedProject.images.length - 1) {
          setSelectedImage(selectedProject.images[currentIndex + 1]);
        }
      }
    },
    onSwipedRight: () => {
      if (selectedImage) {
        const currentIndex = selectedProject.images.indexOf(selectedImage);
        if (currentIndex > 0) {
          setSelectedImage(selectedProject.images[currentIndex - 1]);
        }
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const isFirstImage = selectedImage && selectedProject && selectedProject.images.indexOf(selectedImage) === 0;
  const isLastImage = selectedImage && selectedProject && selectedProject.images.indexOf(selectedImage) === selectedProject.images.length - 1;

  return (
    <div className="projects-container">
      {!selectedProject && (
        <h1 className="projects-heading">Our Projects</h1>
      )}

      {selectedProject ? (
        <div className="project-detail">
          <div className="close-icon" onClick={handleClose}>×</div>
          <button className="back-button" onClick={handleBackClick}>
            <i className="fas fa-arrow-left"></i> Back to Projects
          </button>
          <h2 className="project-title">{selectedProject.title}</h2>
          <p className="project-description">{selectedProject.description}</p>
          <div className="images">
            {selectedProject.images.map((image, index) => (
              <div key={index} className="image-item" onClick={() => handleImageClick(image)}>
                <img src={image.src} alt={`Image ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="projects-list">
          {projectData.map((project) => (
            <div key={project.id} className="project" onClick={() => handleProjectClick(project)}>
              <img src={project.thumbnail} alt={project.title} />
              <h3>{project.title}</h3>
            </div>
          ))}
        </div>
      )}

      {/* Modal for image zoom and swipe */}
      {selectedImage && (
        <Modal
          isOpen={!!selectedImage}
          onRequestClose={handleClose}
          className="modal"
          overlayClassName="overlay"
        >
          <div {...swipeHandlers} className="modal-content no-padding">
            <div className="modal-close" onClick={handleClose}>×</div>
            <img src={selectedImage.src} alt="Selected" className="modal-image no-border" />
            {!isFirstImage && (
              <div className="swipe-icon left" onClick={() => {
                const currentIndex = selectedProject.images.indexOf(selectedImage);
                if (currentIndex > 0) {
                  setSelectedImage(selectedProject.images[currentIndex - 1]);
                }
              }}>
                <i className="fas fa-chevron-left"></i>
              </div>
            )}
            {!isLastImage && (
              <div className="swipe-icon right" onClick={() => {
                const currentIndex = selectedProject.images.indexOf(selectedImage);
                if (currentIndex < selectedProject.images.length - 1) {
                  setSelectedImage(selectedProject.images[currentIndex + 1]);
                }
              }}>
                <i className="fas fa-chevron-right"></i>
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Projects;
