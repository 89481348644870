import React, { useState } from 'react';
import './MenuBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const MenuBar = (props) => {
  const { handleNavChange } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (navOption) => {
    handleNavChange(navOption); 
    setIsOpen(false); 
  };

  return (
    <nav className="menubar">
      <div className="hamburger" onClick={toggleMenu}>
        <FontAwesomeIcon icon={faBars} />
      </div>
      <ul className={`menubar-menu ${isOpen ? 'open' : ''}`}>
        <li className="menubar-item">
          <div onClick={() => handleItemClick("home")} className="menubar-link">Home</div>
        </li>
        <li className="menubar-item">
          <div onClick={() => handleItemClick("aboutus")} className="menubar-link">Our Team</div>
        </li>
        <li className="menubar-item">
          <div onClick={() => handleItemClick("architech")} className="menubar-link">Architecture & Interior Design</div>
        </li>
        <li className="menubar-item">
          <div onClick={() => handleItemClick("service")} className="menubar-link">Services</div>
        </li>
        <li className="menubar-item">
          <div onClick={() => handleItemClick("contact")} className="menubar-link">Contact</div>
        </li>
      </ul>
    </nav>
  );
};

export default MenuBar;
