import React from 'react';
import { Form, Input, Button, Radio, Checkbox, message } from 'antd';
import emailjs from 'emailjs-com';
import Footer from '../home/footer'; 
import './contact.css';
// import Footer from '../home/footer';

const ContactForm = () => {
  const [form] = Form.useForm(); // Ant Design form instance

  const handleSubmit = (values) => {
    if (!values.termsAccepted) {
      message.error('You must agree to the Terms & Conditions.');
      return;
    }

    // EmailJS email sending logic
    const templateParams = {
      name: values.name,
      contactNo: values.contactNo,
      email: values.email,
      message: values.message,
      areasLookingFor: values.areasLookingFor,
      budget: values.budget,
      propertyType: values.propertyType,
      transactionType: values.transactionType,
    };

    emailjs
      .send(
        'service_v3jlnrh', // Replace with your EmailJS service ID
        'template_95rs9xe', // Replace with your EmailJS template ID
        templateParams,
        'WQwr5dWoqlv82apc7' // Replace with your EmailJS public key
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          message.success('Message sent successfully!');
        },
        (err) => {
          console.log('FAILED...', err);
          message.error('Failed to send message, please try again.');
        }
      );
  };

  const handleSubmitFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    message.error('Please fill out the form correctly.');
  };

  return (
   <div>
    <Form
      form={form}
      name="contactForm"
      layout="vertical"
      onFinish={handleSubmit}
      onFinishFailed={handleSubmitFailed}
      autoComplete="off"
    
    >
      <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
      Unlock the Door to Your Dream Property!
      </h2>

      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please enter your name!' }]}
      >
        <Input placeholder="Enter your name" />
      </Form.Item>

      <Form.Item
        label="Contact No."
        name="contactNo"
        rules={[
          { required: true, message: 'Please enter your contact number!' },
          { pattern: /^[0-9]{10}$/, message: 'Please enter a valid 10-digit number!' },
        ]}
      >
        <Input placeholder="Enter your contact number" />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, message: 'Please enter your email!' },
          { type: 'email', message: 'Please enter a valid email address!' },
        ]}
      >
        <Input placeholder="Enter your email" />
      </Form.Item>

      <Form.Item label="Message" name="message">
        <Input.TextArea placeholder="Enter your message" rows={4} />
      </Form.Item>

      <Form.Item
        label="Areas You're Looking For"
        name="areasLookingFor"
        rules={[{ required: true, message: 'Please enter the areas you are looking for!' }]}
      >
        <Input placeholder="Enter areas you're looking for" />
      </Form.Item>

      <Form.Item
        label="Your Budget"
        name="budget"
        rules={[{ required: true, message: 'Please enter your budget!' }]}
      >
        <Input placeholder="Enter your budget" />
      </Form.Item>

      <Form.Item
        label="Types of Property"
        name="propertyType"
        rules={[{ required: true, message: 'Please select a property type!' }]}
      >
        <Radio.Group>
          <Radio value="commercial">Commercial</Radio>
          <Radio value="residential">Residential</Radio>
          <Radio value="mixUse">Mix Use</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="Want to"
        name="transactionType"
        rules={[{ required: true, message: 'Please select a transaction type!' }]}
      >
        <Radio.Group>
          <Radio value="buy">Buy</Radio>
          <Radio value="sell">Sell</Radio>
          <Radio value="rent">Rent</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="termsAccepted"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject('You must accept the terms and conditions'),
          },
        ]}
      >
        <Checkbox>I have read and agree to the Terms & Conditions</Checkbox>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" block>
          Submit
        </Button>
      </Form.Item>
    </Form>
    <Footer/>
    </div>
    
  );
};

export default ContactForm;


