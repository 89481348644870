import React from 'react';
import styled from 'styled-components';
import PersonInfo from './Interior Design and Architecture';
import Projects from './projects';
import Footer from './footer2';

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
  padding-top: 30px; /* Space above the content */

  @media (max-width: 768px) {
    padding-top: 20px;
    height: auto; /* Adjust height for mobile screens */
  }

  @media (max-width: 480px) {
    padding-top: 15px;
  }
`;

const MainContent = styled.div`
  flex-grow: 1;
  padding: 20px;
  text-align: center;
  width: 100%; /* Width to match the Container */

  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

// const Image = styled.img`
//   max-width: 100%;
//   height: auto;
//   margin-top: 20px;
//   border-radius: 10px;

//   @media (max-width: 768px) {
//     margin-top: 15px;
//   }

//   @media (max-width: 480px) {
//     margin-top: 10px;
//   }
// `;

const Architecture = () => {
  return (
    <Container>
      <MainContent>
        <PersonInfo />
        <Projects />
      </MainContent>
      <Footer />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30px' }}>
        <p style={{ textAlign: 'center', fontSize: '16px', margin: '0' }}>&copy; 2024 Akhtar Realtors. All Rights Reserved.</p>
      </div>
    </Container>
  );
};

export default Architecture;
