import React from 'react';
import './aboutus.css'; 
import styled from 'styled-components';

import Aliya from '../assests/aaliyablur.jpg'
import Nahid from '../assests/nahidbajiblur.jpg'
import Uzma from '../assests/uzmabajiblur.jpg'

import Footer from '../home/footer'; 
import Cards from './cards';

const AboutUs = () => {  
  const Aboutus = styled.section`
    
    Background-color:white;
  `;
  
  const IntroSection = styled.section`
    margin: 20px;
    color: #384347;
    font-family: "EB Garamond", serif;
    Background-color:"#EEE6D8";
  `;
  
  const Card = styled.div`
    // background-color: #f9f9f9;
    background-color:#EEE6D7;
    // background-color: #ffffff;
    border-radius: 18px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
     font-family: "EB Garamond", serif;
     font-weight: 500;
    
    font-size:1rem;
  `;
  const IntroParagraph = styled.p`
    margin-bottom: 15px;
    line-height: 1.6;
    font-size:1.2rem;
  `;
  
  
  const IntroPoint = styled.p`
    margin-bottom: 10px;
    line-height: 1.6;
    font-size:1.2rem;
    
  `;
  

  return (
    <Aboutus className="about-us">
     <IntroSection>
      
    <Card>
     <IntroParagraph>
      <strong>Akhtar sisters</strong> are united by a common vision and fueled by their shared passion for the industry, they set out to create a real estate business that would leave an indelible mark.
       But what's a journey without challenges The trio faced skeptics who questioned their abilities in the industry. Undeterred, they turned challenges into opportunities, leveraging their unique skills and perspectives to create a business model that resonated with a diverse clientele.
      </IntroParagraph>
    </Card>
    <Card>
      <IntroPoint>
      <strong>Uzma</strong>, the visionary of the trio, proposed the idea of starting a real estate business, the sisters meticulously outlines their roles and responsibilities.
       <strong>Aliya</strong>, with her charismatic personality and excellent communication skills, takes charge of client relations and marketing,
      <strong>Nahid</strong> , with her creative mind, handles the design, interiors, and architecture part.
      </IntroPoint>
      <IntroPoint>
      In the realm of real estate, excellence is not merely a goal; it is our commitment that shapes every aspect of our business. At Akhtar Realtors, we believe in setting the highest standards and surpassing them with each endeavor. Our pursuit of excellence extends beyond the properties we represent; it encompasses the relationships we build, the communities we serve, and the lasting impact we aspire to create.
      </IntroPoint>
       
     </Card>
     
   </IntroSection>
      {/* CEO Section */}
      <div className="section ceo">
        <div className="image-container">
          <img src={Uzma} alt="CEO" />
        </div>
        <div className="details">
          <h2>Founder & CEO - Uzma Akhtar</h2>
          <p>Uzma Akhtar is a Founder & CEO of Akhtar Realtors- Real Estate Matchmakers, and strives toward creating an excellent, and hassle-free Real-Estate consultancy. She is B.tech in Electronics & Telecommunications and MBA in HR and Marketing. She has served firms like Aptech ltd, Flinto learning Solution, Azuro by Square yards and Karshni Group. She has been recognised with more than 13 Excellency Awards in her 6 years of Career.</p>
        </div>
      </div>

      {/* Co-Founder 1 Section */}
      <div className="section cofounder1">
        <div className="details">
          <h2>Co-Founder & Director- Aliya Akhtar</h2>
          <p>Aliya Akhtar is a Co-Founder of Akhtar Realtors | Indian Real estate match makers. A Dynamic and detail-oriented professional. She holds a bachelor degree from Doon Business School, Dehradun. She has served Real estate firm Karshni Buildwell. She has worked with most of the acclaimed builders and realtors across Mumbai and Thane successfully served more then 100+ Clients.</p>
        </div>
        <div className="image-container">
          <img src={Aliya} alt="Co-Founder 1" />
        </div>
      </div>

      {/* Co-Founder 2 Section */}
      <div className="section cofounder2">
        <div className="image-container">
          <img src={Nahid} alt="Co-Founder 2" />
        </div>
        <div className="details">
          <h2>Co-Founder, Head Architect & Interior Designer - Nahid Akhtar</h2>
          <p>Ar. Nahid Akhtar is a licensed Architect, Graduated from the faculty of planning and Architecture at Jagannath University, Jaipur. She has Served firms like Hospaccx (Mumbai), and ALM Design(Raipur). She has worked on 15+ Healthcare projects in Ukraine, Chhattisgarh, Orissa, Andra Pradesh, Maharashtra etc. She has designed 30+ Residential and 25+ Commercial Project across India.</p>
        </div>
      </div>
      {/* <OurTeam/> */}
      <Cards/>
      <Footer/>
    </Aboutus>
  );
};

export default AboutUs;
