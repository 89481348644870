import React from 'react';
import styled from 'styled-components';
import Footer from './footer'; 
import LadderImage from '../assests/mission11.jpg';
import Testimony from './testimony';

// Styled Components
const IntroSection = styled.section`
  margin: 20px;
  color: #384347;
  background-color: '#EEE6D8';

  @media (max-width: 768px) {
    margin: 10px;
  }
`;

const Card = styled.div`
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 15px;
    margin-bottom: 15px;
  }
`;

const FlexCard = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column; /* Stack text and image in column */
  }
`;

const IntroHeading = styled.h1`
  font-size: 2rem;
  margin-bottom: 20px;
  font-family: "Satisfy", cursive;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
`;

const IntroParagraph = styled.p`
  margin-bottom: 15px;
  line-height: 1.6;
  font-size: 20px;
  font-family: "EB Garamond", serif;
  font-weight: 300;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 1.4;
  }
`;

const MissionVisionSection = styled.section`
  background-color: #EEE6D8;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const MissionVisionHeading = styled.h4`
  font-size: 2rem;
  margin-bottom: 10px;
  font-family: "Satisfy", cursive;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: 8px;
  }
`;

const MissionVisionParagraph = styled.p`
  margin-bottom: 15px;
  line-height: 1.3;
  font-family: "EB Garamond", serif;
  font-size: 20px;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 1.3;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  padding: 20px;
  @media (max-width: 768px) {
    padding: 0; /* Adjust padding for smaller screens */
  }
`;

const AkhtarHome = () => {
  return (
    <IntroSection>
      <Card>
        <IntroHeading>
          Welcome to Akhtar Realtors: Real Estate Matchmakers
        </IntroHeading>
        <IntroParagraph>
          Your Gateway to Exceptional Real Estate Services. Discover a world of possibilities as we guide you through the realm of extraordinary properties. Whether you are seeking your dream home, a lucrative investment, or selling your current property, we are here to turn your real estate aspirations into reality. Let us be your trusted partner on this exciting journey. Our commitment goes beyond just facilitating property transactions; we are dedicated to creating lasting relationships built on trust, transparency, and excellence. At Akhtar Realtors, our motto is not just about buying or selling properties; it's about envisioning a lifestyle, securing investments, and finding your place in the world. Whether you are a first-time homebuyer, an astute investor, or someone looking to upgrade or downsize, we have the expertise and resources to cater to your unique needs.
        </IntroParagraph>
      </Card>

      <FlexCard>
        <div style={{ flex: 1, padding: '20px' }}>
          <MissionVisionHeading>Mission</MissionVisionHeading>
          <MissionVisionParagraph>
            At Akhtar Realtors, our mission is to be a guiding hand through one of life’s most personal and meaningful transitions—finding or creating a home where dreams are nurtured and memories are made. We understand that a home is not just a place but a reflection of your heart, aspirations, and the love you share with your family. That’s why we’re committed to providing deeply personalized, empathetic real estate services, built on trust and care. Whether you’re buying, selling, renting, or designing, we strive to make every step of the journey smooth and fulfilling, ensuring that the home you find or create becomes a sanctuary of peace, warmth, and lasting happiness.
          </MissionVisionParagraph>
          
          <MissionVisionHeading>Vision</MissionVisionHeading>
          <MissionVisionParagraph>
            At Akhtar Realtors, we envision transforming the real estate industry into a respected and formalized profession, where realtors are valued and honored for their expertise and dedication. Our goal is to elevate industry standards and deliver a high-class experience that sets us apart, ensuring that clients choose Akhtar Realtors as their trusted partner every time they seek real estate services. We aspire to be synonymous with excellence in real estate, so whenever someone speaks of the industry, Akhtar Realtors is the name that comes to mind, representing unmatched quality, integrity, and care.
          </MissionVisionParagraph>
        </div>

        <ImageContainer>
          <img src={LadderImage} alt="Ladder" style={{ width: '100%', borderRadius: '10px' }} />
        </ImageContainer>
      </FlexCard>

      <MissionVisionSection>
        <Card>
          <MissionVisionHeading>Why choose us for renting or buying a home?</MissionVisionHeading>
          <MissionVisionParagraph>
            We truly understand the emotional journey of leaving a home full of memories and searching for a new cozy space. We know how important it is to find a space where you can feel at peace after a long day and wake up feeling refreshed. Everyone has unique needs and dreams when it comes to their living space, and we are here to listen with empathy and care. We deeply value your time and strive to make each moment count. We understand the nuances of what you're looking for—a view that greets you with the tranquility of a distant hill or the allure of a sparkling pool, a balcony that invites you to savor chai and pakoras on rainy evenings, or a high floor where the city lights twinkle just for you. Perhaps you need a lower floor to accommodate an elderly family member who prefers stairs over elevators. We don’t want to waste your time sifting through every possibility; instead, we’re dedicated to presenting only the homes that resonate with your dreams and needs, ensuring that each option feels like it was made just for you and your family. Our goal is to help you find a home that feels just right for you, offering the perfect blend of comfort and warmth.
          </MissionVisionParagraph>
        </Card>
      </MissionVisionSection>

      <MissionVisionSection>
        <Card>
          <MissionVisionHeading>Why Choose Us for Architecture and Interior work?</MissionVisionHeading>
          <MissionVisionParagraph>
            With Architect Nahid Akhtar, we are more than just Architects and Interior Designers. Our dedicated team, led by Nahid herself, takes a deeply personal approach to creating your dream home. Both our Head Architect and Interior Designer personally visit every client to ensure that we fully understand your vision. We collaborate closely with you to create a mood board for every section of your house, blending style and functionality. Every design choice, from color palettes to furniture, is meticulously crafted to reflect the unique personality of each family member, ensuring your home truly feels like yours. Instead of chasing fleeting trends, we focus on timeless, one-of-a-kind designs that make your home a trendsetter. Our craftsmanship emphasizes both beauty and durability, ensuring that each piece we create adds lasting value to your space. With Nahid Akhtar, your home becomes a masterpiece—a reflection of your dreams and aspirations.
          </MissionVisionParagraph>
        </Card>
      </MissionVisionSection>

      <Testimony />
      <Footer />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30px' }}>
        <p style={{ textAlign: 'center', fontSize: '16px', margin: '0' }}>
          &copy; 2024 Akhtar Realtors. All Rights Reserved.
        </p>
      </div>
    </IntroSection>
  );
};

export default AkhtarHome;
