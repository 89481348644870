import React from 'react';
import styled from 'styled-components';
import sisterImage from '../assests/akhtarrealotorsistor1.jpg'

// Styled Components
const FooterContainer = styled.footer`
  background-color: #EEE6D8;
  padding: 20px;
  text-align: center;
  color: #333;
  width: 100%;
  font-family: "EB Garamond", serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-size: 24px;

  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const FooterImage = styled.div`
  flex: 1;
  height: 500px;

  @media (max-width: 768px) {
    height: 300px;
    margin-bottom: 20px;
  }

  @media (max-width: 480px) {
    height: 250px;
  }
`;

const FooterImageImg = styled.img`
  max-width: 300px;
  border-radius: 5px;

  @media (max-width: 768px) {
    max-width: 250px;
  }

  @media (max-width: 480px) {
    max-width: 200px;
  }
`;

const FooterContact = styled.div`
  flex: 1;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const FooterContactHeading = styled.h4`
  margin-bottom: 10px;
  font-size: 2.5rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.8rem;
  }
`;

const FooterContactInfo = styled.p`
  margin: 5px 0;
  font-size: 2rem;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }

  @media (max-width: 480px) {
    font-size: 1.6rem;
  }
`;

function Footer() {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterImage>
          <FooterImageImg
            src={sisterImage}
            alt="Company Image"
          />
        </FooterImage>
        <FooterContact>
          <FooterContactHeading>Contact for Architecture & Interior Designing</FooterContactHeading>
          <FooterContactInfo>
            <strong>Address:</strong> Shop no. 01, Divya Complex, Gada Chowk, Supela, Bhilai, Chattisgarh, (490023)
          </FooterContactInfo>
          <FooterContactInfo>
            <strong>Phone No:</strong> 9784907422
          </FooterContactInfo>
          <FooterContactInfo>
            <strong>Email:</strong> nahidakhtararchitects.info@gmail.com
          </FooterContactInfo>
        </FooterContact>
      </FooterContent>
    </FooterContainer>
  );
}

export default Footer;
