import React from 'react';
import styled from 'styled-components';
import Nahid from '../assests/NahidImage.jpeg';
import Architecture from '../assests/architecture.gif'; // Replace with your GIF file path

// Styled components

const PersonContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 40px;
  background-color: whitesmoke;
  border-radius: 10px;
  width: 1300px; 
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 80%;
    padding: 20px;
  }
  
  @media (max-width: 480px) {
    padding: 15px;
  }
`;

const Image = styled.img`
  width: 300px; 
  height: auto;
  border-radius: 5%;
  margin-right: 30px;

  @media (max-width: 768px) {
    width: 80%;
    margin: 0 0 20px 0;
  }

  @media (max-width: 480px) {
    width: 100%;
    margin: 0 0 15px 0;
  }
`;

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px;
  flex: 1;
  margin: 30px 0;
  display: flex;
  align-items: center;
  gap: 30px; 

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
  }

  @media (max-width: 480px) {
    padding: 15px;
  }
`;

const TextSection = styled.div`
  flex: 1;
`;

const Name = styled.h2`
  margin-top: 0;
  color: #333;
  font-size: 2rem;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const Paragraph = styled.p`
  margin-bottom: 15px;
  line-height: 1.6;
  color: #666;
  text-align: left;
  font-size: 1.2rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

const Gif = styled.img`
  width: 50%; /* Adjust the size of the GIF as needed */
  height: auto;
  border-radius: 5%;

  @media (max-width: 768px) {
    width: 70%;
  }

  @media (max-width: 480px) {
    width: 90%;
  }
`;

const PersonInfo = () => {
  return (
    <>
      <PersonContainer>
        <Image src={Nahid} alt='Person' />
        <Card>
          <TextSection>
            <Name>Ar. Nahid Akhtar</Name>
            <Paragraph>
              She is a licensed Architect, Graduated from the faculty of planning and Architecture at Jagannath University, Jaipur, Rajasthan. A passionate architect keen to learn new things and friendly to work with. Architecture for her is an art that works with science to design places and buildings where people can live, interact, work, and play.
            </Paragraph>
          </TextSection>
        </Card>
      </PersonContainer>
      <Card>
        <TextSection>
          <Name>Nahid Akhtar & Architects</Name>
          <Paragraph>
            We are an organization of licensed professionals and trained individuals who use their scientific knowledge, technical skills, and aesthetic persona to create architectural designs. We focus on how design can solve problems and deliver tremendous results for each of our client's demands. We are a community of designers and engineers helping and building around the country. Every project is important and unique for us. We work in all scales and all sectors, we are specialized in residential, commercial, and hospital projects.
          </Paragraph>
        </TextSection>
        <Gif src={Architecture} alt='Example GIF' />
      </Card>
    </>
  );
};

export default PersonInfo;
