import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import coverImage from '../assests/CoverImageFront.jpg';

// Styled components
const ImageContainer = styled.div`
  width: 100%;
  height: 80vh;
  overflow: hidden;
  position: relative;
  margin: 0 auto;

  @media (max-width: 768px) {
    height: 50vh;
  }

  @media (max-width: 480px) {
    height: 40vh;
  }
`;

const Image = styled.img`
  width: 220%;
  height: 80vh;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.2s ease-out;

  @media (max-width: 768px) {
    width: 300%;
    height: 50vh;
  }

  @media (max-width: 480px) {
    width: 400%;
    height: 40vh;
  }
`;

const InfinityImage = () => {
  const [position, setPosition] = useState(0);
  const [hover, setHover] = useState(false);

  // Handle mouse movement for desktop and large screens
  const handleMouseMove = (e) => {
    const container = e.currentTarget.getBoundingClientRect();
    const mouseX = e.clientX - container.left;
    const xPercent = (mouseX / container.width) * 100;
    setPosition(xPercent);
  };

  // Handle touch movement for smaller screens
  const handleTouchMove = (e) => {
    const container = e.currentTarget.getBoundingClientRect();
    const touchX = e.touches[0].clientX - container.left;
    const xPercent = (touchX / container.width) * 100;
    setPosition(xPercent);
  };

  // Automatic movement when not hovering or touching
  useEffect(() => {
    if (!hover) {
      const interval = setInterval(() => {
        setPosition((prevPosition) => (prevPosition >= 100 ? 0 : prevPosition + 5));
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [hover]);

  return (
    <ImageContainer
      onMouseMove={handleMouseMove}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onTouchMove={handleTouchMove} // Enable touch movement
      onTouchStart={() => setHover(true)}  // Treat touch as hover start
      onTouchEnd={() => setHover(false)}   // Treat touch end as hover end
    >
      <Image
        src={coverImage}
        style={{
          transform: `translateX(-${position / 2}%)`,
        }}
        alt="Infinity Image"
      />
    </ImageContainer>
  );
};

export default InfinityImage;
