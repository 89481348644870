import React, { useState } from 'react';
import styled from 'styled-components';

// Styled Components
const TermsContainer = styled.div`
  margin: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const DropdownButton = styled.button`
  background-color: #B39977;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 24px;
  margin-bottom: 10px;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Satisfy", cursive;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 20px; /* Smaller font for tablets */
    padding: 8px 16px;
  }

  @media (max-width: 576px) {
    font-size: 18px; /* Even smaller font for mobile */
    padding: 6px 12px;
  }
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 10px;
  margin-top: 10px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjust for tablets */
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr; /* Stack cards on smaller screens */
  }
`;

const Card = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const CardTitle = styled.h4`
  margin-bottom: 10px;
  color: #364C79;
  font-family: "Satisfy", cursive;
  font-weight: 400;
  font-size: 24px;

  @media (max-width: 768px) {
    font-size: 20px; /* Smaller font for tablets */
  }

  @media (max-width: 576px) {
    font-size: 18px; /* Smaller font for mobile */
  }
`;

const CardContent = styled.p`
  margin-bottom: 10px;
  line-height: 1.6;
  font-size: 20px;
  font-family: "EB Garamond", serif;
  font-weight: 300;

  @media (max-width: 768px) {
    font-size: 18px; /* Smaller font for tablets */
  }

  @media (max-width: 576px) {
    font-size: 16px; /* Smaller font for mobile */
  }
`;

const termsContent = [
  {
    title: "Builder Purchase",
    content: "Our services are FREE OF COST i.e. ZERO PERCENT Fees from the buyer/purchaser."
  },
  {
    title: "Resale",
    content: "We charge 2% commission on the total sale/purchase value."
  },
  {
    title: "Long Lease / Rent",
    content: "Our rental commission structure on Residential and Commercial properties is based on a monthly rent, we charge 1 month of the rent."
  },
  {
    title: "Payment",
    content: "Payment is due immediately at the time of signing of the agreement or at the time of registration."
  },
  {
    title: "Confidentiality",
    content: "Akhtar Realtors agrees to keep all client information confidential and will not disclose any confidential information without the client's explicit consent, except as required by the law."
  },
  {
    title: "Amendments",
    content: "Akhtar Realtors reserves the right to amend these terms and conditions at any time. By engaging in real estate services with Akhtar Realtors, the client acknowledges and agrees to these terms and conditions. These terms may be subject to change, and the clients will be notified of any amendments."
  }
];

function TermsAndConditions() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <TermsContainer>
      <DropdownButton onClick={toggleDropdown}>
        Terms and Conditions
        <span>{isOpen ? '▲' : '▼'}</span>
      </DropdownButton>
      {isOpen && (
        <CardContainer>
          {termsContent.map((term, index) => (
            <Card key={index}>
              <CardTitle>{term.title}</CardTitle>
              <CardContent>{term.content}</CardContent>
            </Card>
          ))}
        </CardContainer>
      )}
    </TermsContainer>
  );
}

export default TermsAndConditions;
