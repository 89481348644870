import React from 'react';
import styled from 'styled-components';
import sisterImage from '../assests/akhtarrealotorsistor1.jpg';

// Styled Components
const FooterContainer = styled.footer`
  background-color: #EEE6D8;
  padding: 20px;
  text-align: center;
  color: #333;
  font-family: "EB Garamond", serif;
  font-weight: 300;

  @media (max-width: 768px) {
    padding: 10px; /* Reduce padding for smaller screens */
  }
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */

  @media (max-width: 768px) {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items */
  }
`;

const FooterImage = styled.div`
  flex: 1;
  height: 500px;
  display: flex;
  justify-content: center; /* Center image */
  align-items: center;

  @media (max-width: 768px) {
    height: auto; /* Allow height to adjust automatically */
    margin-bottom: 20px; /* Add some margin at the bottom */
  }
`;

const FooterImageImg = styled.img`
  max-width: 100%; /* Responsive width */
  max-height: 100%; /* Responsive height */
  border-radius: 5px;
`;

const FooterContact = styled.div`
  flex: 1;
  text-align: left;
  padding: 10px; /* Add some padding */

  @media (max-width: 768px) {
    text-align: center; /* Center text on smaller screens */
    padding: 0; /* Remove padding on mobile */
  }
`;

const FooterContactHeading = styled.h4`
  margin-bottom: 10px;
  font-size: 2.5rem;

  @media (max-width: 768px) {
    font-size: 1.5rem; /* Smaller font size for mobile */
  }
`;

const FooterContactInfo = styled.p`
  margin: 5px 0;
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 1.25rem; /* Smaller font size for mobile */
    line-height: 1.4; /* Increase line height for better readability */
    margin: 4px 0; /* Add a bit more margin for spacing */
    padding-bottom:5px;
  }
`;

function Footer() {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterImage>
          <FooterImageImg
            src={sisterImage}
            alt="Company Image"
          />
        </FooterImage>
        <FooterContact>
          <FooterContactHeading>Contact for Buy/Sell & Rent</FooterContactHeading>
          <FooterContactInfo>
            <strong>Address:</strong> Piramal Vaikunth, Balkum Naka, Thane West, Thane, Maharashtra 400607
          </FooterContactInfo>
          <FooterContactInfo>
            <strong>Phone No:</strong> 9685456313 | 9193106653
          </FooterContactInfo>
          <FooterContactInfo>
            <strong>Email:</strong> uzma@akhtarrealtors.in 
          </FooterContactInfo>
        </FooterContact>
      </FooterContent>
    </FooterContainer>
  );
}

export default Footer;
